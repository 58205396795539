const developmentRoutes = [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "PieChartIcon",
    vendorAllow: true,
  },

  
];

const productionRoutes = [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "PieChartIcon",
    vendorAllow: true,
  },

  
];

export default process.env.VUE_APP_ENVIRON &&
process.env.VUE_APP_ENVIRON === "DEVELOPMENT"
  ? developmentRoutes
  : productionRoutes;
